"use strict";

var onegini = onegini || {};

var pollStatus = function pollStatus(ttl) {
  var maxNumOfCalls = Math.max(1, Math.floor(ttl / 1000));
  var isTransactionCompleted = false;
  var currentCallIndex = 0;

  var checkStatus = function checkStatus() {
    jQuery.ajax({
      type: 'GET',
      url: window.location.protocol + "//" + window.location.host + '/connect/mobile-auth/completed',
      dataType: 'text',
      success: function success(data) {
        if (typeof data === "boolean" && data || data === 'true') {
          isTransactionCompleted = true;
        } else {
          currentCallIndex++;
        }
      },
      error: function error() {
        currentCallIndex = maxNumOfCalls;
      }
    });
    return isTransactionCompleted;
  };

  var asyncCheckStatus = function asyncCheckStatus() {
    checkStatus();
    setTimeout(function () {
      if (!isTransactionCompleted && currentCallIndex < maxNumOfCalls) {
        asyncCheckStatus();
      } else if (isTransactionCompleted) {
        window.location.href = window.location.pathname + '/proceed';
      }
    }, 1000);
  };

  asyncCheckStatus();
};

onegini.qrCode = onegini.qrCode || function () {
  $('#qr-code-button-generation').click(function () {
    window.location.reload();
  });

  var handleQRLoginCodeResult = function handleQRLoginCodeResult(data) {
    if (data.initOtpAuthenticationResponse.status === 'SUCCESS') {
      var qr = handleQRCodeGeneration(data.initOtpAuthenticationResponse.otp);
      handleQRCodeDisplay(data, qr);
    } else {
      $('#qr-code-button-generation-container').hide();
      $('#qr-code').empty();
      $('#qr-code-error').show();
    }
  };

  var handleQRCodeGeneration = function handleQRCodeGeneration(otp) {
    var typeNumber = 0;
    var errorCorrectionLevel = 'L';
    var qr = qrcode(typeNumber, errorCorrectionLevel);
    qr.addData(otp);
    qr.make();
    return qr;
  };

  var handleQRCodeDisplay = function handleQRCodeDisplay(data, qr) {
    var timeout = calculateRemainingTimeout(data);
    displayQRCode(qr);
    addQRCodeTimeoutCallback(timeout);
    pollStatus(timeout);
  };

  var calculateRemainingTimeout = function calculateRemainingTimeout(data) {
    return data.initOtpAuthenticationResponse.timeout - (data.now - data.initiatedAt);
  };

  var displayQRCode = function displayQRCode(qr) {
    document.getElementById('qr-code').innerHTML = qr.createImgTag(4);
    $('#qr-code-button-generation-container').hide();
  };

  var addQRCodeTimeoutCallback = function addQRCodeTimeoutCallback(timeout) {
    setTimeout(function () {
      $('#qr-code-button-generation-container').show();
      $('#qr-code').empty();
    }, timeout);
  };

  $('#qr-code-error').hide();

  if (typeof qrData !== "undefined" && qrData !== null && qrData !== false) {
    handleQRLoginCodeResult(qrData);
  }
}();